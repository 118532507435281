import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	Button,
	Col,
	Form,
	Modal,
	Row,
	Stack,
	Toast,
	ToastContainer,
} from "react-bootstrap";
import { CheckCircleFill, ExclamationCircleFill } from "react-bootstrap-icons";
import Spinner from "react-bootstrap/Spinner";
import { type SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import type { IDelegationRequestForm } from "../../Interfaces/IDelegationRequestForm";
import { userContext } from "../../components/Context/UserContext";

const Details = () => {
	const { oktaAuth } = useOktaAuth();
	const { id } = useParams();
	const history = useHistory();
	const user = useContext(userContext);

	const [error, setError] = useState<any>();
	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [showFailToast, setShowFailToast] = useState(false);
	const [show, setShow] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isDesignator, setDesignator] = useState();

	const { handleSubmit, setValue, reset, register, getValues } =
		useForm<IDelegationRequestForm>({
			mode: "onChange",
		});

	const handleShow = () => setShow(true);

	const handleClose = () => setShow(false);

	const handleConfirmNo = () => {
		setShow(false);
	};

	const handleResponse = async () => {
		setShowSuccessToast(true);
		history.push("/");
	};

	const handleError = async (error) => {
		setShowFailToast(true);
		setError(error);
	};

	const getDataAsync = async (id) => {
		const accessToken = await oktaAuth.getAccessToken();
		const formurl = `${process.env.REACT_APP_ENDPOINT_URL}/api/Forms/${id}`;
		return await axios.get<Response>(formurl, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
	};

	const postDataAsync = async (data) => {
		const accessToken = await oktaAuth.getAccessToken();
		const dataUrl = `${process.env.REACT_APP_ENDPOINT_URL}/api/Forms`;
		return await axios.post(dataUrl, data, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
	};

	const onSubmit: SubmitHandler<IDelegationRequestForm> = (data, event) => {
		setShow(false);
		setValue("status", event?.target.value);

		data.status = event?.target.value;

		postDataAsync(data)
			.then((post) => handleResponse())
			.catch((error) => handleError(error));
	};

	const resetForm = async (result) => {
		result.data.startDate = result.data.startDate.toString().substr(0, 10);
		result.data.expiryDate = result.data.expiryDate.toString().substr(0, 10);
		result.data.createdDate = result.data.createdDate.toString().substr(0, 10);
		reset(result.data);
	};

	const resetAsyncForm = useCallback(async () => {
		await getDataAsync(id).then((result: any) => {
			if (
				result.data.designator.employeeId === user.bamboohrId ||
				result.data.designee.employeeId === user.bamboohrId ||
				user.isAdmin
			) {
				resetForm(result);
				setDesignator(result.data.designator.employeeId);
			} else {
				history.push("/");
			}
		});
	}, [reset, user]);

	const getFile = async (fileName) => {
		const accessToken = await oktaAuth.getAccessToken();
		const formurl = `${process.env.REACT_APP_ENDPOINT_URL}/api/FileStorage/DownloadFile/${id}_${fileName}`;
		const fileResponse = await axios
			.get<any>(formurl, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then(({ data }) => {
				const downloadUrl = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");
				link.href = downloadUrl;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
			});
	};

	useEffect(() => {
		if (!user || !user.bamboohrId) return;

		setIsLoaded(false);
		resetAsyncForm();
		setIsLoaded(true);
	}, [reset, user]);

	const currentDate = new Date();
	const isExpired = currentDate > new Date(getValues("expiryDate"));

	return (
		<>
			{isLoaded ? (
				<div className="container">
					<ToastContainer className="p-3" position="top-center">
						<Toast
							bg="success"
							onClose={() => setShowSuccessToast(false)}
							show={showSuccessToast}
							delay={3000}
							autohide
						>
							<Toast.Header>
								<CheckCircleFill className="me-2" color="green" size={16} />

								<strong className="me-auto">Success</strong>
								<small>just now</small>
							</Toast.Header>
							<Toast.Body className="text-white">
								Delegation Successfully Updated!
							</Toast.Body>
						</Toast>
					</ToastContainer>

					<ToastContainer className="p-3" position="top-center">
						<Toast
							bg="danger"
							onClose={() => setShowFailToast(false)}
							show={showFailToast}
							delay={3000}
							autohide
						>
							<Toast.Header>
								<ExclamationCircleFill className="me-2" color="red" size={16} />

								<strong className="me-auto">Failure</strong>
								<small>just now</small>
							</Toast.Header>
							<Toast.Body className="text-white">
								Delegation Failed to Save!
								<br />
								<span>{error?.message}</span>
							</Toast.Body>
						</Toast>
					</ToastContainer>

					<Form>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Status:
							</Form.Label>
							<Col sm="10">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="text"
									readOnly
									className="form-control-plaintext"
									{...register("status")}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Designator:
							</Form.Label>
							<Col sm="4">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="text"
									readOnly
									className="form-control-plaintext"
									{...register("designator.name")}
								/>
							</Col>

							<Form.Label column sm="2">
								Designee:
							</Form.Label>
							<Col sm="4">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="text"
									readOnly
									className="form-control-plaintext"
									{...register("designee.name")}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Start Date:
							</Form.Label>
							<Col sm="4">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="date"
									readOnly
									className="form-control-plaintext"
									{...register("startDate")}
								/>
							</Col>

							<Form.Label column sm="2">
								End Date:
							</Form.Label>
							<Col sm="4">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="date"
									readOnly
									className="form-control-plaintext"
									{...register("expiryDate")}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Delegated Authorities:
							</Form.Label>
							<Col sm="10">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="text"
									readOnly
									className="form-control-plaintext"
									{...register("functions")}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Applications:
							</Form.Label>
							<Col sm="10">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="text"
									readOnly
									className="form-control-plaintext"
									{...register("applications")}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Comments:
							</Form.Label>
							<Col sm="10">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="text"
									readOnly
									className="form-control-plaintext"
									{...register("comments")}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Attachment:
							</Form.Label>
							<Col sm="10">
								<Form.Control
									style={{ backgroundColor: "inherit" }}
									type="button"
									onClick={(e: any) => getFile(e.target.value)}
									className="form-control-plaintext"
									{...register("fileName")}
								/>
							</Col>
						</Form.Group>

						{getValues("revokeComments") && (
							<Form.Group as={Row} className="mb-3">
								<Form.Label column sm="2">
									Revoke Comments:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										disabled
										style={{ backgroundColor: "inherit" }}
										type="text"
										readOnly
										className="form-control-plaintext"
										{...register("revokeComments")}
									/>
								</Col>
							</Form.Group>
						)}
						<Form.Group as={Row} className="mb-3 form-check">
							<div className="form-check-inline">
								<input
									className="form-check-input"
									type="checkbox"
									id="gridCheck4"
									checked
									disabled
								/>
								<label className="form-check-label" htmlFor="gridCheck4">
									I've read and agree to the Delegation of Authority Policy
									CAP-7011
								</label>
							</div>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="2">
								Created On:
							</Form.Label>
							<Col sm="10">
								<Form.Control
									disabled
									style={{ backgroundColor: "inherit" }}
									type="date"
									readOnly
									className="form-control-plaintext"
									{...register("createdDate")}
								/>
							</Col>
						</Form.Group>

						<Stack direction="horizontal" gap={3} className="mb-5">
							<Link to="/" className="btn btn-secondary me-auto" type="button">
								Return
							</Link>

							{user.isAdmin &&
								!isExpired &&
								getValues("status") !== "Revoked" && (
									<button
										className="btn btn-prussian-blue"
										type="button"
										onClick={() =>
											history.push(`/DelegationRequestFormAdmin/${id}`)
										}
									>
										Edit
									</button>
								)}

							{!isExpired &&
								getValues("status") !== "Revoked" &&
								(isDesignator === user?.bamboohrId || user.isAdmin) && (
									<button
										className="btn btn-danger"
										type="button"
										onClick={handleShow}
										value="Revoked"
									>
										Revoke
									</button>
								)}
						</Stack>

						<Modal show={show} onHide={handleClose}>
							<Modal.Header closeButton>
								<Modal.Title>Revoke Delegation</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="bg-skyblue-80-tint p-3 fw-bold">
									Are you sure you want to revoke this delegation?
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Col sm="12">
									<label htmlFor="revokeComments">
										Comments:<span className="text-danger">*</span>
									</label>
									<textarea
										{...register("revokeComments", { required: true })}
										rows={3}
										className="w-100"
										placeholder="Please enter a reason"
									/>
								</Col>
								<Col sm="5">
									<Button
										className="w-100"
										variant="matterhorn"
										onClick={handleConfirmNo}
									>
										No
									</Button>
								</Col>

								<Col sm="5" className="mx-4">
									<Button
										className="w-100"
										variant="prussian-blue"
										value="Revoked"
										onClick={handleSubmit(onSubmit)}
									>
										Yes
									</Button>
								</Col>
							</Modal.Footer>
						</Modal>
					</Form>
				</div>
			) : (
				<div className="d-flex justify-content-center vh-100">
					<div className="align-self-center">
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				</div>
			)}
		</>
	);
};
export default Details;
